:root {
  --theme-color: var(--primary) ;
}
.btn1-theme{
  background: var(--theme-color);
  color: var(--white);
}
.btn1-theme:hover{
  background: var(--secondary);
  color: var(--white);
}
.btn2-theme{
  background: var(--white1);
  color: var(--black1);
}
.btn2-theme:hover{
  background: var(--secondary);
  color: var(--white);
}
body{
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  color: var(--black);
  font-size: 16px;
}
a {
  color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
}
a:hover {
  color: #f0ae2e;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-not-allowed{
  cursor: not-allowed;
}
.btn:disabled{
  pointer-events: inherit;
  cursor: not-allowed;
}
.profile-img{
  height: 40px;
  max-height: 100%;
}

/* error boundry start */
.error-boundary {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100vh;
}
  
.error-boundary .error-boundary-text {
    font-size: 1.25rem;
    text-align: center;
}
/* error boundry end */
/* validation error */
.invalid-feedback.error{
  display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}
/* notification toast msg */
.notification-error h4{
  color: #fff;
}
.notification-container .notification .title{
  color: #fff;
}
body, #root, .main-layout{
    min-height: 100vh;
}
.page-content{
    min-height: 30rem;
}
.logo img {
    width: 50px
}
.fade.toast.show{
    z-index: 99999;
}
/* modals */
.school-notification-modal .modal-title{
  color: var(--bs-heading-color) !important;
}
/* react select */
.form-group-select{
  margin-bottom: 1.5rem;
  color: #8898aa;
}
/* filter section */
.custom-select-input .select__input-container, .custom-select-input .select__single-value, .custom-select .select__dropdown-indicator, .custom-select-input .select__indicator-separator, .custom-select-input .select__indicator, .custom-select-input .select__placeholder{
  color: #fff !important;
}
.custom-select-input .select__control{
  /* border: 1px solid rgba(145, 158, 171, 0.32) !important;
  box-shadow: unset !important; */

  /* width: 100%;
  height: calc(2.75rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7 !important;
  border-radius: 0.375rem;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) */

  border-radius: 30px;
  background-color: transparent;
  color: var(--white);
  font-size: 10px;
  padding: 0px 0px 0px 5px;
}
/* .custom-select-input .select__control--menu-is-open{
  border-color: var(--theme-color) !important;
  box-shadow: unset !important;
} */
.custom-select-input .select__menu{
  z-index: 20 !important;
}
.custom-select-input .select__control--is-focused{
  border-color: #e9ecef !important;
  box-shadow: unset !important ;
}
.custom-select-input .select__control--is-disabled{
  background-color: #e9ecef;
}
.custom-select-input .select__option{
  color:var(--theme-color);
  font-size: 10px;
}
.custom-select-input .select__option--is-focused, .custom-select-input .select__option--is-selected{
  background-color: var(--theme-color) !important;
  color: #fff;
}
.custom-select-input.is-invalid .select__control
{
  border-color: #dc3545 !important;
}
/* form section */
.custom-select-form-input .select__input-container, .custom-select-form-input .select__single-value, .custom-select .select__dropdown-indicator, .custom-select-form-input .select__indicator-separator, .custom-select-form-input .select__indicator, .custom-select-form-input .select__placeholder{
  color: #565656 !important;
}

.custom-select-form-input .select__control{

  /* border-radius: 30px; */
  background-color: transparent;
  color: var(--bs-body-color);
  font-size: 1rem;
    font-weight: 400;
  /* font-size: 10px; */
  padding: 0px 0px 0px 5px;
}
/* .custom-select-form-input .select__control--menu-is-open{
  border-color: var(--theme-color) !important;
  box-shadow: unset !important;
} */
.custom-select-form-input .select__menu{
  z-index: 20 !important;
}
.custom-select-form-input .select__control--is-focused{
  /* border-color: #e9ecef !important;
  box-shadow: unset !important ; */

  border-color: var(--primary) !important;
  box-shadow: 0 0 0 0.25rem rgba(240,174,39,.25) !important;
}
.custom-select-form-input .select__control--is-disabled{
  background-color: #e9ecef;
}
.custom-select-form-input .select__option{
  color:var(--theme-color);
  /* font-size: 10px; */
  font-size: 15px;
}
.custom-select-form-input .select__option--is-focused, .custom-select-form-input .select__option--is-selected{
  background-color: var(--theme-color) !important;
  color: #fff;
}
.custom-select-form-input.is-invalid .select__control
{
  border-color: #dc3545 !important;
}
/* datepicker */
.react-datepicker-popper{
  z-index: 2 !important;
}
/* datepicker range */
.react-datepicker__day--keyboard-selected{
  color: #fff !important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected:hover{
  background-color: var(--theme-color) !important;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
  background-color: rgba(102, 51, 153, 0.5);
}
.datepicker-input-form .react-datepicker-wrapper{
  width: 100%;
}
.form-control-label {
  font-weight: bold;
}
/* swal confirm popup */
.swal2-confirm.tft_btn{background-color: var(--primary); color: var(--white); border-radius: 30px; padding: 10px 40px; font-size: 17px; font-weight: 800; white-space: nowrap;}
.swal2-confirm.tft_btn:hover{background-color: var(--secondary); color: var(--white);}
.swal-custom-confirm-popup
{
  width: 23.75em !important;
  border-radius: 0px !important;
  padding: 2.5rem !important;
}
.swal-custom-confirm-html-container{
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  text-align: inherit !important;
  line-height: 24px !important
}
/* swal message popup */
.swal-custom-msg-popup
{
  width: 502px !important;
  border-radius: 0px !important;
  padding: 2.5rem !important;
}
.swal-custom-msg-html-container{
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  line-height: 24px !important
}
.swal-custom-msg-icon{
  font-size: 18px !important;
}
/* antd spinner --------------*/
.ant-spin-dot-item{
  background-color: var(--theme-color) !important;
}